import { LocalizedLink as Link } from "gatsby-theme-i18n"

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 120,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

// TODO: デザイン適用
const InternalServerErrorPage = () => {
  return (
    <main style={pageStyles}>
      <title>Internal Server Error</title>
      <h1 style={headingStyles}>Internal Server Error</h1>
      <p style={paragraphStyles}>
        Internal Server Error
        <br />
        <Link to="/">Go home</Link>.
      </p>
    </main>
  )
}

export default InternalServerErrorPage
